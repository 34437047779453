import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { onError, onSuccess } from '../../module/common/services';
import { IMessage } from '../../module/common/types';
import { walletService } from '../../services/wallet/wallet.service';
import { IAuthError, IWallet, IWalletDelete, IWalletUpdate } from '../../types';

interface IPost {
  options?: {
    addOnSuccess?: () => void;
    addOnError?: (err: AxiosError<IAuthError>) => void;
  };
}

const post = ({ options }: IPost) => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IWallet>(
    async (data: IWallet) => await walletService.post(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_LIST]);
        onSuccess({ message: data.message });
        options?.addOnSuccess && options?.addOnSuccess();
      },
      onError: (err: AxiosError<IAuthError>) => {
        onError(err);
        options?.addOnError && options.addOnError(err);
      }
    }
  );
};
const put = () => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IWalletUpdate>(
    async (data: IWalletUpdate) => await walletService.put(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_LIST]);
        onSuccess({ message: data.message });
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};
const remove = () => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IWalletDelete>(
    async (data: IWalletDelete) => await walletService.remove(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_LIST]);
        onSuccess({ message: data.message });
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

export const useMutationWallet = {
  post,
  put,
  remove
};
