import { getIn, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';

import { useClickOutside } from '../../hooks';
import './drop-down-list.css';

export interface IItemList {
  name: string;
}

export interface IDropDownList {
  list: IItemList[];
  name: string;
  title?: string;

  noFormikValue?: {
    value: { name: string };
    onSetValue: (name: string, value: IItemList) => void;
  };

  setValue?: string;
  titleStyle?: any;
  wrapperStyle?: any;
  listStyle?: any;
  isFormattedNames?: boolean;
  readOnly?: boolean;
  inputStyle?: any;
  class_name?: 'gray' | 'black' | 'white';
  isToUp?: boolean;

  addOptionalOnChange?: (name: string, value: any) => void;
}

export const DropDownlist = ({
  addOptionalOnChange,
  list = [],
  name,
  title,
  titleStyle,
  wrapperStyle,
  listStyle,
  isFormattedNames = false,
  noFormikValue,
  readOnly = false,

  class_name = 'black',
  isToUp,
  setValue,
  inputStyle
}: IDropDownList) => {
  const [isOpen, setIsOpen] = useState<boolean | null>(false);
  const { setFieldValue, value } = noFormikValue
    ? {
        value: noFormikValue.value,
        setFieldValue: noFormikValue.onSetValue
      }
    : {
        value: getIn(useFormikContext().values, name),
        setFieldValue: useFormikContext().setFieldValue
      };

  const refSelectedItem = useRef(null);
  const refList = useRef<any>(null);
  const handleSelectItem = (item: IItemList) => {
    // @ts-ignore
    const _value = setValue ? item[setValue] : item;
    setFieldValue(name, _value);
    addOptionalOnChange && addOptionalOnChange(name, _value);
    setIsOpen(false);
  };

  const handleOpenList = () => {
    if (!readOnly) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleOnBlur = () => {
    setIsOpen(false);
  };

  const readOnlyStyle = readOnly
    ? {
        cursor: 'default',
        borderColor: 'rgba(118, 118, 118, 0.3)'
      }
    : {};

  const { ref } = useClickOutside(() => setIsOpen(false));

  const _list = list;

  return (
    <div
      className={`wrapper ${class_name}`}
      style={{ ...wrapperStyle, ...readOnlyStyle }}
      onBlur={handleOnBlur}
    >
      {title && (
        <div className='titleWrapper'>
          <span className='title' style={{ ...titleStyle }}>
            {title}
          </span>
        </div>
      )}
      <div
        className={isOpen ? 'input inputActive' : 'input'}
        onClick={handleOpenList}
        style={{ ...inputStyle }}
      >
        <span>{value ? isFormattedNames ? value.name : <>{value?.name ?? value}</> : 'test'}</span>
        {isOpen ? (
          <svg xmlns='http://www.w3.org/2000/svg' height='30' viewBox='0 -960 960 960' width='30'>
            <path
              d='m291-349.463-37.153-37.152L480-612.768l226.153 226.153L669-349.463l-189-189-189 189Z'
              fill='black'
            />
          </svg>
        ) : (
          <svg xmlns='http://www.w3.org/2000/svg' height='30' viewBox='0 -960 960 960' width='30'>
            <path
              d='M480-346.463 253.847-572.615 291-609.768l189 189 189-189 37.153 37.153L480-346.463Z'
              fill='black'
            />
          </svg>
        )}
      </div>
      {isOpen && (
        <ul
          ref={ref}
          className='list'
          style={{
            ...listStyle
          }}
        >
          {_list.length &&
            _list.map((item: IItemList | any, index: number) => {
              const _name = item.name;
              const _nameVisibility = item.name;

              const _item = item;

              if (_name) {
                return (
                  <li
                    ref={_name === value?.name ? refSelectedItem : null}
                    key={index}
                    className={`${_name === (value?.name || value) ? 'itemSelected' : 'item'}`}
                    onClick={handleSelectItem.bind(this, _item)}
                  >
                    {isFormattedNames ? _nameVisibility : <>{_nameVisibility}</>}
                  </li>
                );
              }
              return null;
            })}
        </ul>
      )}
    </div>
  );
};
