import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { useMutationAdmin, useQueryAdmin } from '../../../../hooks';
import { COLORS, FONTS } from '../../../../theme';
import { SIZES } from '../../../../theme/fonts.const';
import { IEditUser, ISubmitUser } from '../../../../types';
import { ModalConfirm } from '../../../common/component';
import { DropDownlist } from '../../../common/component/drop-down-list/drop-downlist';
import { Pagination } from '../../../common/component/pagination/pagination.component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { DivCommon, IconCommon } from '../../../common/styles';
import * as Styled from './admin-table.styled';

interface IAdminTableProps {
  search: string;
  status: string;
}

export const AdminTable = ({ status, search }: IAdminTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [editUser, setEditUser] = useState<IEditUser | null>(null);
  const [isOpenDeleteUser, setIsOpenDeleteUser] = useState<null | number>(null);
  const [isOpenUpdateUser, setIsOpenUpdateUser] = useState<null | number>(null);

  const { data: allUsers } = useQueryAdmin.allUsers({
    page: currentPage,
    keyword: search,
    status
  });

  const { mutate: mutateUpdateUser } = useMutationAdmin.putUser();
  const { mutate: mutateRemoveUser } = useMutationAdmin.removeUser();

  const onEditUser = (value: IEditUser | null) => {
    setEditUser(value);
  };

  const onSubmitEdit = (data: ISubmitUser) => {
    mutateUpdateUser({ userId: data.id, status: data.status.name, type: data.type.name });
    setEditUser(null);
    onSetIsOpenUpdate(null);
  };

  const onSetIsOpenUpdate = (data: null | number) => {
    setIsOpenUpdateUser(data);
  };

  const onSetIsOpenDelete = (data: null | number) => {
    setIsOpenDeleteUser(data);
  };

  const deleteUser = () => {
    if (isOpenDeleteUser) {
      mutateRemoveUser({ id: isOpenDeleteUser });
      onSetIsOpenDelete(null);
    }
  };

  return (
    <DivCommon width='90%' ai='center' margin='1.5rem auto 0 auto' height='70svh'>
      <Formik
        initialValues={{
          type: { name: editUser?.type ?? '' },
          status: { name: editUser?.status ?? '' },
          id: editUser?.id ?? 0
        }}
        enableReinitialize
        onSubmit={onSubmitEdit}
      >
        {({ values }) => (
          <Form style={{ flex: '1 1 auto' }}>
            <StyledTable.Table>
              <StyledTable.Head>
                <StyledTable.Row>
                  <StyledTable.HeadRow>№</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Name</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Email</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Type</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Date</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Status</StyledTable.HeadRow>

                  <StyledTable.HeadRow className='button'>
                    <IconCommon icon={Icons.edit} height={FONTS.SIZES.xxxxl} />
                  </StyledTable.HeadRow>

                  <StyledTable.HeadRow className='button'>
                    <IconCommon icon={Icons.basket} height={FONTS.SIZES.xxxxl} />
                  </StyledTable.HeadRow>
                </StyledTable.Row>
              </StyledTable.Head>

              <StyledTable.Body>
                {allUsers && allUsers.users.length
                  ? allUsers.users.map((value, index) => (
                      <>
                        <Styled.BodyRow
                          className='body'
                          key={`${value.id}_${index}`}
                          isEdit={editUser?.id === value.id}
                          isBlur={editUser?.id ? editUser.id !== value.id : false}
                        >
                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.id.toString()}
                            >
                              {index + 1}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.nick_name}
                            >
                              {value.nick_name}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.email}
                            >
                              {value.email}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.type}
                            >
                              {value.type}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='token'
                              linesToTruncate={1}
                              tooltipText={value.created_at}
                            >
                              {value.created_at}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='token'
                              linesToTruncate={1}
                              tooltipText={value.status}
                            >
                              {value.status}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data
                            className={editUser?.id === value.id ? 'doubleButton' : 'button'}
                            onClick={onEditUser.bind(this, value)}
                          >
                            <StyledTable.EditButton type='button'>
                              <IconCommon
                                icon={editUser?.id === value.id ? Icons.editActive : Icons.edit}
                                cursor='pointer'
                              />
                            </StyledTable.EditButton>
                          </StyledTable.Data>
                          {editUser?.id !== value.id && (
                            <StyledTable.Data
                              className='button'
                              onClick={onSetIsOpenDelete.bind(this, value.id)}
                            >
                              <StyledTable.EditButton type='button'>
                                <IconCommon icon={Icons.basket} cursor='pointer' />
                              </StyledTable.EditButton>
                            </StyledTable.Data>
                          )}
                        </Styled.BodyRow>

                        {editUser?.id === value.id && (
                          <Styled.BodyRow className='body edit_item' key={`${value.id}${index}`}>
                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.id.toString()}
                              >
                                {index + 1}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.nick_name}
                              >
                                {value.nick_name}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.email}
                              >
                                {value.email}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.type}
                              >
                                <DropDownlist
                                  list={[{ name: 'user' }, { name: 'admin' }]}
                                  name='type'
                                  listStyle={{ top: '2.5rem' }}
                                  wrapperStyle={{
                                    border: `0.0625rem solid ${COLORS.black100}`,
                                    height: '2.25rem',
                                    margin: `${SIZES.xxxxxs} 0`
                                  }}
                                />
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.created_at}
                              >
                                {value.created_at}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.status}
                              >
                                <DropDownlist
                                  list={[{ name: 'approved' }, { name: 'blocked' }]}
                                  name='status'
                                  listStyle={{ top: '2.5rem' }}
                                  wrapperStyle={{
                                    border: `0.0625rem solid ${COLORS.black100}`,
                                    height: '2.25rem',
                                    margin: `${SIZES.xxxxxs} 0`
                                  }}
                                />
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='confirm'>
                              <StyledTable.EditButton
                                type='button'
                                onClick={onSetIsOpenUpdate.bind(this, 1)}
                              >
                                <IconCommon icon={Icons.confirm} cursor='pointer' />
                              </StyledTable.EditButton>
                            </StyledTable.Data>

                            <StyledTable.Data
                              className='cancel'
                              onClick={onEditUser.bind(this, null)}
                            >
                              <StyledTable.EditButton>
                                <IconCommon icon={Icons.cancel} cursor='pointer' />
                              </StyledTable.EditButton>
                            </StyledTable.Data>
                          </Styled.BodyRow>
                        )}
                      </>
                    ))
                  : null}
              </StyledTable.Body>
            </StyledTable.Table>
            {isOpenUpdateUser ? (
              <ModalConfirm
                onClose={onSetIsOpenUpdate.bind(this, null)}
                title='Are You Shure?'
                titleButtonConfirm='Confirm'
                titleButtonCancel='Cancel'
                onClickConfirm={onSubmitEdit.bind(this, values)}
                onClickCancel={onSetIsOpenUpdate.bind(this, null)}
              />
            ) : null}
          </Form>
        )}
      </Formik>

      {allUsers?.count ? null : <Styled.NoData>No Data</Styled.NoData>}

      {isOpenDeleteUser ? (
        <ModalConfirm
          onClose={onSetIsOpenDelete.bind(this, null)}
          title='Are You Shure?'
          titleButtonConfirm='Confirm'
          titleButtonCancel='Cancel'
          onClickConfirm={deleteUser}
          onClickCancel={onSetIsOpenDelete.bind(this, null)}
        />
      ) : null}

      {/*{allUsers?.count && allUsers?.count > 50 && (*/}
      <Pagination
        onPageChange={setCurrentPage}
        currentPage={currentPage}
        totalCount={allUsers?.count ?? 0}
        pageSize={50}
      />
      {/*)}*/}
    </DivCommon>
  );
};
