// Local storage keys
export const STORAGE_KEYS = {
  TOKEN: 'TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  USER: 'USER',
  USER_EMAIL: 'USER_EMAIL'
};

export const ROUTER_KEYS = {
  ADMIN: '/admin',
  HOME: '/parcer',
  LOGIN: '/',
  FORGOT_PASSWORD: '/forgot-password',
  CREATE_PASSWORD: '/change-password',
  SING_UP: '/signup'
};

export const QUERY_KEYS = {
  GET_ME: 'get-me',
  ALL_USERS: 'all-users',
  ALL_LIST: 'all-list',
  CURRENT_ADDRESS: 'current-address',
  TITLE_PAGE: 'title-page',
  WALLET_PAGE: 'wallet-page',
  WALLET_ALL_PAGES: 'wallet-all-pages',
  WALLET_PAGE_INFO: 'wallet-page-info'
};
