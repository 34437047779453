import { api } from '../../module/common/services';
import { ExpectedFromQuery, IMessage } from '../../module/common/types';
import { IAllUserFilters, IAllUsersList, IUpdateUser, IUserRemove } from '../../types';

export const adminService = {
  allUsers: async ({
    page,
    keyword = '',
    status = ''
  }: IAllUserFilters): Promise<ExpectedFromQuery<any>> => {
    const { data } = await api.get<IAllUsersList>('/admin/all-users', {
      params: { page, keyword, status }
    });

    return data;
  },

  putUser: async (data: IUpdateUser): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.put<IMessage>('/admin/put-user', data);

    return _data;
  },

  removeUser: async (data: IUserRemove): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.delete<IMessage>(`/admin/delete-user?userId=${data.id}`);

    return _data;
  }
};
