import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { APP_KEYS } from '../../module/common/constants';
import { onError, onSuccess } from '../../module/common/services';
import { IMessage } from '../../module/common/types';
import { authService } from '../../services';
import {
  IAuthError,
  IChangePassword,
  IForgotPassword,
  ILogin,
  ILoginResponse,
  ISingUp
} from '../../types';

const login = () => {
  const navigate = useNavigate();

  return useMutation<any, AxiosError<IAuthError>, ILogin>(
    async (data: ILogin) => await authService.login(data),
    {
      onSuccess: ({ accessToken: token, refreshToken }: ILoginResponse) => {
        localStorage.setItem(APP_KEYS.STORAGE_KEYS.TOKEN, token);
        localStorage.setItem(APP_KEYS.STORAGE_KEYS.REFRESH_TOKEN, refreshToken);

        navigate(APP_KEYS.ROUTER_KEYS.HOME);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const singUp = () => {
  const navigate = useNavigate();

  return useMutation<any, AxiosError<IAuthError>, ISingUp>(
    async (data: ISingUp) => await authService.singUp(data),
    {
      onSuccess: (_data: IMessage) => {
        onSuccess({ message: _data.message });
        navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const forgotPassword = () => {
  const navigate = useNavigate();

  return useMutation<any, AxiosError<IAuthError>, IForgotPassword>(
    async (data: IForgotPassword) => await authService.forgotPassword(data),
    {
      onSuccess: (_data: IMessage) => {
        onSuccess({ message: _data.message });
        navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const changePassword = () => {
  const navigate = useNavigate();

  return useMutation<any, AxiosError<IAuthError>, IChangePassword>(
    async (data: IChangePassword) => await authService.changePassword(data),
    {
      onSuccess: (_data: IMessage) => {
        onSuccess({ message: _data.message });
        navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

export const useMutationAuth = {
  login,
  singUp,
  forgotPassword,
  changePassword
};
