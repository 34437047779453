import styled from 'styled-components';

import { COLORS } from '../../../../theme';

export const ContentDiv = styled.div`
  display: flex;
`;

export const ContentDivColumn = styled(ContentDiv)`
  flex-direction: column;
`;

export const Icon = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
`;

export const Undo = styled.p`
  font-style: normal;

  color: ${COLORS.purple};

  text-transform: uppercase;
  cursor: pointer;
`;
