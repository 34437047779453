import React from 'react';

import { SPACES } from '../../../../theme';
import { DivCommon, TitleCommon } from '../../styles';
import { Button } from '../buttons';
import { PopupLayout } from '../popup-layout/popup-layout';
import { Portal } from '../portal';
import { Wrapper } from './modal-confirm.styled';

interface IModalConfirmProps {
  onClose: () => void;
  title: string;
  titleButtonConfirm: string;
  titleButtonCancel: string;
  onClickConfirm: (data?: any) => void;
  onClickCancel: () => void;
}

export const ModalConfirm = ({
  onClose,
  title,
  titleButtonConfirm,
  titleButtonCancel,
  onClickConfirm,
  onClickCancel
}: IModalConfirmProps) => {
  return (
    <Portal>
      <Wrapper>
        <PopupLayout onClose={onClose}>
          <TitleCommon>{title}</TitleCommon>
          <DivCommon fd='row' ai='flex-end' jc='space-between'>
            <Button
              mt={SPACES.xxxxl}
              width='14.375rem'
              height='2.875'
              content={titleButtonConfirm}
              type='button'
              variant='primary'
              onClick={onClickConfirm}
            />
            <Button
              mt={SPACES.xxxxl}
              content={titleButtonCancel}
              width='14.375rem'
              height='2.875'
              type='button'
              variant='inverse'
              onClick={onClickCancel}
            />
          </DivCommon>
        </PopupLayout>
      </Wrapper>
    </Portal>
  );
};
