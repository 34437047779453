import { Form, Formik } from 'formik';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMutationAuth } from '../../../../hooks';
import { COLORS, SPACES } from '../../../../theme';
import { IResetPasswordFormik } from '../../../../types';
import { Button, Input, Loader } from '../../../common/component';
import { APP_KEYS, InputsConst } from '../../../common/constants';
import { SubTitleCommon, TitleCommon } from '../../../common/styles';
import { PageLayout } from '../../common';
import { validationSchemaCreatePassword } from '../../validation/shema';

export const CreatePassword = () => {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutationAuth.changePassword();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetLink = queryParams.get('resetLink') ?? '';
  const onSubmit = (data: IResetPasswordFormik) => {
    mutate({ resetLink, newPassword: data.password, confirmNewPassword: data.confirm_password });
  };

  const onNavigate = () => {
    navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  return (
    <PageLayout isBackground={false}>
      <Formik
        initialValues={{
          password: '',
          confirm_password: ''
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchemaCreatePassword}
      >
        {({ isValid }) => (
          <Form>
            <TitleCommon>Create Password</TitleCommon>

            <Input mt={SPACES.xxxxl} type='password' height='3.5rem' {...InputsConst.password} />

            <Input
              mt={SPACES.xxxxl}
              type='password'
              height='3.5rem'
              {...InputsConst.confirm_password}
            />

            <Button
              mt={SPACES.xxxxxxl}
              mb={SPACES.xxxxl}
              content={
                !isLoading ? 'Confirm' : <Loader size='small' color={COLORS.green} height='auto' />
              }
              type='submit'
              variant='primary'
              disabled={!isValid || isLoading}
            />
            <SubTitleCommon color={COLORS.black50} mt={SPACES.xxs}>
              Back to
              <SubTitleCommon
                cursor='pointer'
                ml={SPACES.xs}
                color={COLORS.green}
                onClick={onNavigate}
              >
                Log in
              </SubTitleCommon>
            </SubTitleCommon>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};
