import styled from 'styled-components';

import { COLORS } from '../../theme';
import { SubTitleCommon } from '../common/styles';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 2.75rem 4.0625rem 0 4.0625rem;
`;

export const HeaderTitle = styled(SubTitleCommon)`
  width: fit-content;
  display: flex;
  gap: 1.125rem;
  font-size: 0.9375rem;
  font-weight: 500;
`;

export const AdminContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 4.375rem 0 4.375rem;

  & .search > div {
    top: 1.0625rem;
  }

  & .wrapper {
    position: relative;
    width: 18.625rem;
    height: 2.9375rem;
    border: 0.0625rem solid ${COLORS.black115};
    border-radius: 0.25rem;

    & div.input {
      height: 2.9375rem;
    }

    & > ul.list {
      //text-transform: uppercase;
      top: 2.9rem;
    }
  }
`;
