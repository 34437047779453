import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icons } from '../../assets';
import { useMutationUser, useQueryUser } from '../../hooks';
import { COLORS, FONTS, SPACES } from '../../theme';
import { Button, Input, Loading } from '../common/component';
import { DropDownlist } from '../common/component/drop-down-list/drop-downlist';
import { APP_KEYS } from '../common/constants';
import { DivCommon, IconCommon } from '../common/styles';
import * as Styled from './admin.styled';
import { AdminTable } from './components/table/admin-table';

export const Admin = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  const { isLoading: isLoadingUser, data: user } = useQueryUser.getByIdOrToken();
  const { mutate: mutateLogout } = useMutationUser.logout();

  if (isLoadingUser) {
    return <Loading className='full-screen' />;
  }

  return (
    <DivCommon width='81.25rem' jc='center'>
      <Styled.HeaderContainer>
        <Styled.HeaderTitle>
          {user?.user.nick_name}
          <IconCommon
            icon={Icons.logout}
            background={COLORS.mainRed}
            height={FONTS.SIZES.l}
            cursor='pointer'
            onClick={() => mutateLogout({})}
          />
        </Styled.HeaderTitle>

        <Button
          width='7.3125rem'
          height='2.1875rem'
          mt={SPACES.l}
          content='Workspace'
          type='submit'
          variant='primary'
          onClick={() => navigate(APP_KEYS.ROUTER_KEYS.HOME)}
        />
      </Styled.HeaderContainer>

      <Styled.AdminContainer>
        <Input
          className='search'
          startIcon={{ icon: Icons.search }}
          name='search'
          placeholder='Enter'
          width='18.625rem'
          height='2.9375rem'
          noFormikValue={{
            value: search,
            onSetValue: (name, value) => setSearch(value)
          }}
        />

        <DropDownlist
          list={[
            { name: 'All' },
            { name: 'moderation' },
            { name: 'approved' },
            { name: 'blocked' }
          ]}
          name='allStatus'
          noFormikValue={{
            value: { name: status || 'All' },
            onSetValue: (name, value) => setStatus(value.name)
          }}
        />
      </Styled.AdminContainer>

      <AdminTable search={search} status={status} />
    </DivCommon>
  );
};
