import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { FONTS } from '../../../../theme';
import { IPageInfo } from '../../../../types';
import { Drawer } from '../../../common/component';
import { IconCommon } from '../../../common/styles';
import * as Styled from '../../merch.styled';
import { EditPage } from '../edit-page/edit-page';

interface ITitlePage {
  pageInfo: IPageInfo;
}

export const TitlePage = ({ pageInfo }: ITitlePage) => {
  const titlePage = pageInfo.title || 'Title Page';

  const [editTitlePage, setEditTitlePage] = useState(false);

  const onEditTitlePage = (flag: boolean) => {
    setEditTitlePage(flag);
  };

  return (
    <>
      <Styled.HeaderPageInfo fs='2.25rem'>
        {titlePage}
        <IconCommon
          icon={Icons.edit}
          height={FONTS.SIZES.xxxxl}
          cursor='pointer'
          onClick={onEditTitlePage.bind(this, true)}
        />
      </Styled.HeaderPageInfo>

      <Drawer onClose={onEditTitlePage.bind(this, false)} open={editTitlePage}>
        <EditPage onClose={onEditTitlePage.bind(this, false)} pageId={pageInfo.id} />
      </Drawer>
    </>
  );
};
