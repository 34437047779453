import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutationAuth } from '../../../../hooks';
import { COLORS, SPACES } from '../../../../theme';
import { ISingUpFormik } from '../../../../types';
import { Button, CheckBoxFormik, Input, Loader } from '../../../common/component';
import { APP_KEYS, InputsConst } from '../../../common/constants';
import { SubTitleCommon, TitleCommon } from '../../../common/styles';
import { PageLayout } from '../../common';
import { validationSchemaSingUp } from '../../validation/shema';

export const SingUp = () => {
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutationAuth.singUp();

  const onSubmit = (data: ISingUpFormik) => {
    delete data.confirm_password;
    delete data.privacy_policy;

    mutate(data);
  };

  const onNavigate = () => {
    navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  const onBlur = () => {
    window.scrollTo(0, 0);
  };

  return (
    <PageLayout isBackground={false}>
      <Formik
        initialValues={{
          nick_name: '',
          email: '',
          password: '',
          confirm_password: ''
        }}
        // onSubmit={onSubmit}
        onSubmit={async (values, { setValues, setTouched }) => {
          await onSubmit(values);
          setValues((v: any) => ({ ...v, password: '', confirm_password: '' }));
          setTouched({ password: false, confirm_password: false });
        }}
        validationSchema={validationSchemaSingUp}
      >
        {({ isValid }) => (
          <Form onBlur={onBlur}>
            <TitleCommon fs='2.875rem'>Sign Up</TitleCommon>

            {(['nick_name', 'email'] as (keyof typeof InputsConst)[]).map((v, index) => (
              <Input key={index} mt={SPACES.xxxxl} {...InputsConst[v]} type={v} height='3rem' />
            ))}

            <Input mt={SPACES.xxxxl} type='password' height='3.5rem' {...InputsConst.password} />

            <Input
              mt={SPACES.xxxxl}
              type='password'
              height='3.5rem'
              {...InputsConst.confirm_password}
            />

            <CheckBoxFormik
              mt={SPACES.l}
              name='privacy_policy'
              label='I agree to all Terms and Privacy Policy'
              colorText={COLORS.black50}
              background={COLORS.blue}
            />

            <Button
              mt={SPACES.xxxxl}
              content={
                !isLoading ? (
                  'Create An Account'
                ) : (
                  <Loader size='small' color={COLORS.blue} height='auto' />
                )
              }
              type='submit'
              variant='primary'
              disabled={!isValid || isLoading}
            />

            <SubTitleCommon color={COLORS.black50} mt={SPACES.xxs}>
              Already have an account?
              <SubTitleCommon
                cursor='pointer'
                ml={SPACES.xxxxs}
                onClick={onNavigate}
                color={COLORS.blue}
              >
                Log in
              </SubTitleCommon>
            </SubTitleCommon>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};
