import { api } from '../../module/common/services';
import { ExpectedFromQuery, IMessage, IToken } from '../../module/common/types';
import { IChangePassword, IForgotPassword, ILogin, ISingUp } from '../../types';

export const authService = {
  login: async (data: ILogin): Promise<ExpectedFromQuery<IToken>> => {
    const { data: _data } = await api.post<IToken>('/auth/login', data);

    return _data;
  },

  singUp: async (data: ISingUp): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.post<IMessage>('/auth/registration', data);

    return _data;
  },

  forgotPassword: async (data: IForgotPassword): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.post<IMessage>('/auth/reset-password', data);

    return _data;
  },

  changePassword: async (data: IChangePassword): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.put<IMessage>(
      `/auth/change-password/${data.resetLink}`,
      data
    );

    return _data;
  }
};
