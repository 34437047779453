import { api } from '../../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid } from '../../module/common/types';
import {
  IPageCreate,
  IPageRemove,
  IPageResponse,
  IPagesResponse,
  IUpdatePageTitle
} from '../../types';

export const pageService = {
  all: async (): Promise<ExpectedFromQuery<IPagesResponse>> => {
    const { data } = await api.get<IPagesResponse>('/page/all');

    return data;
  },

  get: async (_data: Iuuid): Promise<ExpectedFromQuery<IPageResponse>> => {
    const { data } = await api.get<IPageResponse>(`/page/?pageId=${_data.id}`);

    return data;
  },

  post: async (data: IPageCreate): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.post<IMessage>('/page/', data);

    return _data;
  },

  put: async (data: IUpdatePageTitle): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.put<IMessage>('/page/', data);

    return _data;
  },

  remove: async (data: IPageRemove): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.delete<IMessage>(`/page/?pageId=${data.pageId}`);

    return _data;
  }
};
