import React, { ReactNode } from 'react';

import { useDisableScroll } from '../../hooks';
import s from './popup-layout.module.css';

export interface IPopupLayout {
  children?: ReactNode;
  onClose: () => void;
  onBack?: () => void;

  disabledOutside?: boolean;

  title?: string;
}

export const PopupLayout = ({
  children,
  onClose,
  onBack,
  title,
  disabledOutside = false
}: IPopupLayout) => {
  useDisableScroll(true);

  return (
    <div className={s.container} onClick={disabledOutside ? () => {} : onClose}>
      <div
        className={s.content}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {title && <div className={s.back}>{title}</div>}

        {onBack && (
          <div className={s.back} onClick={onBack}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M11.8254 3.91107C11.9595 3.78939 12.1358 3.72475 12.3168 3.73087C12.4978 3.73698 12.6693 3.81337 12.7949 3.94383C12.9205 4.07428 12.9904 4.24855 12.9897 4.42965C12.989 4.61076 12.9177 4.78447 12.7911 4.91392L5.10714 12.3054H22.5206C22.7053 12.3054 22.8825 12.3787 23.0131 12.5093C23.1437 12.6399 23.2171 12.8171 23.2171 13.0018C23.2171 13.1865 23.1437 13.3636 23.0131 13.4942C22.8825 13.6248 22.7053 13.6982 22.5206 13.6982H5.10807L12.7911 21.0887C12.9177 21.2182 12.989 21.3919 12.9897 21.573C12.9904 21.7541 12.9205 21.9283 12.7949 22.0588C12.6693 22.1893 12.4978 22.2656 12.3168 22.2718C12.1358 22.2779 11.9595 22.2132 11.8254 22.0916L3.07078 13.6704C2.98077 13.5837 2.90916 13.4798 2.86025 13.3649C2.81134 13.2499 2.78613 13.1262 2.78613 13.0013C2.78613 12.8764 2.81134 12.7527 2.86025 12.6378C2.90916 12.5228 2.98077 12.4189 3.07078 12.3323L11.8254 3.91107Z'
                fill='black'
              />
            </svg>
          </div>
        )}

        <div className={s.close} onClick={onClose}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
          >
            <path
              d='M16 15.9987L9.33333 9.33203M16 15.9987L22.6667 22.6654M16 15.9987L22.6667 9.33203M16 15.9987L9.33333 22.6654'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>

        <div className={s.children}>{children}</div>
      </div>
    </div>
  );
};
