import { useEffect } from 'react';

export const useDisableScroll = (condition?: boolean) => {
  const container = document.getElementById('container');

  const disableScroll = () => container?.classList.add('no-scroll');
  const enableScroll = () => container?.classList.remove('no-scroll');

  useEffect(() => {
    if (typeof condition === 'undefined') return;

    if (condition) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [condition]);

  return {
    enableScroll,
    disableScroll
  };
};
