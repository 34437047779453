import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { adminService } from '../../services';
import { IAllUserFilters, IAllUsersList } from '../../types';

const allUsers = (data: IAllUserFilters): UseQueryResult<IAllUsersList> =>
  useQuery(
    [APP_KEYS.QUERY_KEYS.ALL_USERS, data.page, data.keyword, data.status],
    async () => await adminService.allUsers(data)
  );

export const useQueryAdmin = {
  allUsers
};
