import React from 'react';
import {Route, BrowserRouter as Router, Routes, Navigate} from 'react-router-dom';

import { COLORS } from '../../theme';
import { Admin } from '../admin/admin';
import { ForgotPassword, Login, SingUp } from '../auth/component';
import { CreatePassword } from '../auth/component/create-password/create-password';
import { Loader } from '../common/component';
import { APP_KEYS } from '../common/constants';
import { ProtectedPage, PublicPage } from '../common/hocs';
import { Merch } from '../merch/merch';

export const MainRouter = () => (
  <Router>
    <React.Suspense
      fallback={<Loader size='medium' height='auto' margin='50%' color={COLORS.purple} />}
    >
      <Routes>
        <Route
          path={APP_KEYS.ROUTER_KEYS.LOGIN}
          element={
            <PublicPage>
              <Login />
            </PublicPage>
          }
        />

        <Route
          path={APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD}
          element={
            <PublicPage>
              <ForgotPassword />
            </PublicPage>
          }
        />

        <Route
          path={APP_KEYS.ROUTER_KEYS.CREATE_PASSWORD}
          element={
            <PublicPage>
              <CreatePassword />
            </PublicPage>
          }
        />

        <Route
          path={APP_KEYS.ROUTER_KEYS.HOME}
          element={
            <ProtectedPage>
              <Merch />
            </ProtectedPage>
          }
        />

        <Route
          path={APP_KEYS.ROUTER_KEYS.ADMIN}
          element={
            <ProtectedPage>
              <Admin />
            </ProtectedPage>
          }
        />

        <Route
          path={APP_KEYS.ROUTER_KEYS.SING_UP}
          element={
            <PublicPage>
              <SingUp />
            </PublicPage>
          }
        />
          <Route
              path='*'
              element={<Navigate to={APP_KEYS.ROUTER_KEYS.HOME} />}
          />
      </Routes>
    </React.Suspense>
  </Router>
);
