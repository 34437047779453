import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { APP_KEYS } from '../../module/common/constants';
import { onError, onSuccess } from '../../module/common/services';
import { IMessage } from '../../module/common/types';
import { pageService } from '../../services';
import {
  IAuthError,
  IPageCreate,
  IPageRemove,
  IPagesResponse,
  IUpdatePageTitle
} from '../../types';

const post = (allPage: number) => {
  const navigate = useNavigate();
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IPageCreate>(
    async (data: IPageCreate) => await pageService.post(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.WALLET_ALL_PAGES]);
        onSuccess({ message: data.message });
        navigate(`${APP_KEYS.ROUTER_KEYS.HOME}?page=${allPage + 1}`);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const put = () => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IUpdatePageTitle>(
    async (data: IUpdatePageTitle) => await pageService.put(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_LIST]);
        onSuccess({ message: data.message });
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const remove = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const [query] = useSearchParams();

  return useMutation<any, AxiosError<IAuthError>, IPageRemove>(
    async (data: IPageRemove) => await pageService.remove(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.WALLET_ALL_PAGES]);
        onSuccess({ message: data.message });
        navigate(`${APP_KEYS.ROUTER_KEYS.HOME}?page=${+(query.get('page') ?? '1') - 1}`);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

export const useMutationPages = {
  post,
  put,
  remove
};
