import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { onError, onSuccess } from '../../module/common/services';
import { IMessage } from '../../module/common/types';
import { adminService } from '../../services';
import { IAuthError, IUpdateUser, IUserRemove } from '../../types';

const putUser = () => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IUpdateUser>(
    async (data: IUpdateUser) => await adminService.putUser(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_USERS]);
        onSuccess({ message: data.message });
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const removeUser = () => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IUserRemove>(
    async (data: IUserRemove) => await adminService.removeUser(data),
    {
      onSuccess: (data: IMessage) => {
        client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_USERS]);
        onSuccess({ message: data.message });
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

export const useMutationAdmin = {
  putUser,
  removeUser
};
