import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { userService } from '../../services/user/user.service';
import { IUserResponse } from '../../types';

const getByIdOrToken = (): UseQueryResult<IUserResponse> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_ME], async () => await userService.getByIdOrToken());

export const useQueryUser = {
  getByIdOrToken
};
