import styled, { css } from 'styled-components';

import { COLORS, FONTS, SHADOWS, SPACES } from '../../../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACES.m} 0;
  background-color: inherit;
`;

export const PaginateButtonsList = styled.ul`
  list-style: none;

  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${SPACES.xxxxl};
  }
`;
export const PaginateButtonsListItem = styled.li``;

const buttonsCommonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;

  background-color: ${COLORS.white};
  color: ${COLORS.black30};

  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${FONTS.SIZES.m};

  border-radius: 50%;
  cursor: pointer;
`;

export const PaginationButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  ${buttonsCommonStyles}
  border-radius: ${SPACES.xxs};

  &.selected {
    color: ${COLORS.green};
    // background-color: ${COLORS.white50};
    filter: ${SHADOWS.xxl};
  }
`;

const prevNextBtnStyles = css`
  ${buttonsCommonStyles}
  width: 2.5rem;
  height: 2.5rem;

  // background-color: ${COLORS.white50};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.5rem;
  &:hover {
    background-color: ${COLORS.green};
  }
  &:disabled {
    opacity: 0.5;
    background-color: ${COLORS.white};

    & > .icon {
      color: ${COLORS.white};
    }
  }

  opacity: 0.5;

  &:active:not(:disabled) {
    transform: scale(0.98);
  }
`;

export const PrevPageButton = styled.button`
  ${prevNextBtnStyles}
  margin-right: auto;
`;

export const NextPageButton = styled.button`
  ${prevNextBtnStyles}
  margin-left: auto;
`;
