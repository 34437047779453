import axios from 'axios';

import * as APP_KEYS from '../constants/app-keys.const';
import {toastContainer} from "../component/toast";

const url = process.env.REACT_APP_SERVER_URL;

export const api = axios.create({
  baseURL: `${url}`
});

api.interceptors.request.use((config: any) => {
  const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);
  if (config.url.includes('logout')) {
    const refresh = localStorage.getItem(APP_KEYS.STORAGE_KEYS.REFRESH_TOKEN);
    config.headers = {
      Authorization: `Bearer ${token}` || null,
      'Content-Type': 'application/json',
      update: `${refresh}`
    };
  } else {
    config.headers = {
      Authorization: `Bearer ${token}` || null,
      'Content-Type': 'application/json'
    };
  }

  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    // const navigate = useNavigate()
    // const dispatch = useDispatch()

    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);
    const refresh = localStorage.getItem(APP_KEYS.STORAGE_KEYS.REFRESH_TOKEN);
    const originalRequest = error.config;

    const headers = {
      Authorization: `Bearer ${token}` || null,
      update: `${refresh}`
    };

    if (error?.response?.status === 401 && error?.config && refresh) {
      originalRequest.secondTime = true;
      try {
        const response = await axios.post(
          `${url}/user/refresh`,
          {},
          {
            headers: headers
          }
        );

        localStorage.setItem(APP_KEYS.STORAGE_KEYS.TOKEN, response.data.accessToken);
        localStorage.setItem(APP_KEYS.STORAGE_KEYS.REFRESH_TOKEN, response.data.refreshToken);

        return api.request(originalRequest);
      } catch (err: any) {
        if (err?.response?.status === 401) {
          toastContainer.error({ title: "Session expired" });

          await axios.post(
            `${url}/user/logout`,
            {},
            {
              headers: headers
            }
          );

          localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
          localStorage.removeItem(APP_KEYS.STORAGE_KEYS.REFRESH_TOKEN);
          localStorage.clear();

          window.location.href = process.env.REACT_APP_CLIENT_URL as string;

        }
      }
    }

    throw error?.response?.data || error;
  }
);
