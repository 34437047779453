import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useMutationPages, useQueryPages } from '../../../../hooks';
import { COLORS, FONTS, SPACES } from '../../../../theme';
import { Button, Input, Loader, Loading, ModalConfirm } from '../../../common/component';
import { PopupLayout } from '../../../common/component/popup-layout/popup-layout';
import { Portal } from '../../../common/component/portal';
import { DivCommon, TitleCommon } from '../../../common/styles';
import * as Styled from './edit-page.styled';
import { ButtonWrapper, EditWrapper, Wrapper } from './edit-page.styled';

export interface IEditPage {
  onClose: () => void;
  pageId: number;
}

export const EditPage = ({ onClose, pageId }: IEditPage) => {
  const [query] = useSearchParams();
  const page = +(query.get('page') ?? '1');

  const [isDelete, setIsDelete] = useState(false);
  const [isEditPage, setIsEditPage] = useState(false);

  const { isLoading, data } = useQueryPages.get({ id: pageId });

  const mutateEditTitlePage = useMutationPages.put();
  const mutateRemovePage = useMutationPages.remove();

  const toggleIsDelete = (flag?: boolean) => {
    setIsDelete(flag ?? false);
  };
  const toggleIsEditPage = (flag?: boolean) => {
    setIsEditPage(flag ?? false);
  };

  const onSubmitEditTitlePage = (_data: any) => {
    mutateEditTitlePage.mutate({ title: _data.titlePage, pageId: pageId ?? 0 });
    onClose();
  };

  const removePage = () => {
    mutateRemovePage.mutate({ pageId });
    onClose();
  };

  return (
    <Styled.Container>
      {isLoading ? <Loading className='full-screen' /> : null}

      <DivCommon fd='row' ai='center'>
        <TitleCommon fw={FONTS.WEIGHTS.semi_bold}>Page Settings</TitleCommon>
        <Styled.CloseBtn onClick={onClose} />
      </DivCommon>

      <Formik
        initialValues={{
          titlePage: data?.page?.title ?? ''
        }}
        enableReinitialize
        onSubmit={onSubmitEditTitlePage}
      >
        {({ values }) => (
          <Form style={{ height: '95%' }}>
            <Wrapper>
              <EditWrapper>
                <Input
                  mt={SPACES.xxxxl}
                  height='3.5rem'
                  name='titlePage'
                  label='title page'
                  autoFocus
                  className='edit_title_page'
                />
              </EditWrapper>

              <ButtonWrapper>
                <Button
                  content={
                    !mutateEditTitlePage.isLoading ? (
                      'Confirm'
                    ) : (
                      <Loader size='small' color={COLORS.blue} height='auto' />
                    )
                  }
                  mt={SPACES.s}
                  // type='submit
                  type='button'
                  onClick={toggleIsEditPage.bind(this, true)}
                  variant='primary'
                  disabled={mutateEditTitlePage.isLoading}
                />
                <Button
                  mt={SPACES.s}
                  content='Cancel'
                  type='button'
                  variant='inverse'
                  background={COLORS.mainRed}
                  onClick={onClose.bind(this, false)}
                />

                {page > 1 ? (
                  <Button
                    mt={SPACES.xxxxxxl}
                    content='Delete Page'
                    type='button'
                    variant='inverse'
                    background={COLORS.mainRed}
                    onClick={toggleIsDelete.bind(this, true)}
                  />
                ) : null}
              </ButtonWrapper>
            </Wrapper>
            {isEditPage ? (
              <ModalConfirm
                onClose={toggleIsEditPage.bind(this, false)}
                title='Are You Shure?'
                titleButtonConfirm='Confirm'
                titleButtonCancel='Cancel'
                onClickConfirm={onSubmitEditTitlePage.bind(this, values)}
                onClickCancel={toggleIsEditPage.bind(this, false)}
              />
            ) : null}
          </Form>
        )}
      </Formik>

      {isDelete ? (
        <ModalConfirm
          onClose={toggleIsDelete.bind(this, false)}
          title='Are You Shure?'
          titleButtonConfirm='Confirm'
          titleButtonCancel='Cancel'
          onClickConfirm={removePage}
          onClickCancel={toggleIsDelete}
        />
      ) : null}
    </Styled.Container>
  );
};
