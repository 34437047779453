import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutationAuth } from '../../../../hooks';
import { COLORS, SPACES } from '../../../../theme';
import { Button, Input, Loader } from '../../../common/component';
import { APP_KEYS, InputsConst } from '../../../common/constants';
import { SubTitleCommon, TitleCommon } from '../../../common/styles';
import { PageLayout } from '../../common';
import { validationSchemaForgot } from '../../validation/shema';

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const isLoading = false;
  const { mutate } = useMutationAuth.forgotPassword();

  const onSubmit = (data: { email: string }) => {
    mutate({ email: data.email });
  };

  const onNavigate = () => {
    navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  return (
    <PageLayout isBackground={false}>
      <Formik
        initialValues={{
          email: ''
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchemaForgot}
      >
        {({ isValid }) => (
          <Form>
            <TitleCommon>Forgot Password?</TitleCommon>

            {(['email'] as (keyof typeof InputsConst)[]).map((v, index) => (
              <Input key={index} mt={SPACES.xxxxl} {...InputsConst[v]} type={v} height='3rem' />
            ))}

            <Button
              mt={SPACES.xxxxxxl}
              mb={SPACES.xxxxl}
              content={
                !isLoading ? (
                  'Reset Password'
                ) : (
                  <Loader size='small' color={COLORS.green} height='auto' />
                )
              }
              type='submit'
              variant='primary'
              disabled={!isValid || isLoading}
            />
            <SubTitleCommon color={COLORS.black50} mt={SPACES.xxs}>
              Back to
              <SubTitleCommon
                cursor='pointer'
                ml={SPACES.xs}
                color={COLORS.green}
                onClick={onNavigate}
              >
                Log in
              </SubTitleCommon>
            </SubTitleCommon>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};
