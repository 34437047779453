import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutationAuth } from '../../../../hooks';
import { COLORS, SPACES } from '../../../../theme';
import { ILogin } from '../../../../types';
import { Button, CheckBoxFormik, Input, Loader } from '../../../common/component';
import { APP_KEYS, InputsConst } from '../../../common/constants';
import { SubTitleCommon, TitleCommon } from '../../../common/styles';
import { PageLayout } from '../../common';
import { validationSchemaLogin } from '../../validation/shema';
import { ForgotWrapper } from './login.styled';

interface _ILogin extends ILogin {
  is_remember?: boolean;
}

export const Login = () => {
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutationAuth.login();

  const onSubmit = (data: _ILogin) => {
    delete data.is_remember;

    mutate(data);
  };

  const onNavigate = () => {
    navigate(APP_KEYS.ROUTER_KEYS.SING_UP);
  };
  const onNavigateForgot = () => {
    navigate(APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD);
  };

  return (
    <PageLayout isBackground={false}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          is_remember: false
        }}
        onSubmit={async (values: ILogin, { setValues, setTouched }) => {
          await onSubmit(values);
          setValues((v: any) => ({ ...v, password: '' }));
          setTouched({ password: false });
        }}
        validationSchema={validationSchemaLogin}
      >
        {({ isValid }) => (
          <Form>
            <TitleCommon>Sign In</TitleCommon>

            {(['email', 'password'] as (keyof typeof InputsConst)[]).map((v, index) => (
              <Input key={index} mt={SPACES.xxxxl} {...InputsConst[v]} type={v} height='3rem' />
            ))}

            <ForgotWrapper>
              <CheckBoxFormik
                name='is_remember'
                label='Remember for 30 days'
                colorText={COLORS.black50}
                background={COLORS.blue}
              />

              <SubTitleCommon cursor='pointer' onClick={onNavigateForgot} color={COLORS.blue}>
                Forgot password
              </SubTitleCommon>
            </ForgotWrapper>

            <Button
              mt={SPACES.xxxxxxl}
              content={
                !isLoading ? 'Sign In' : <Loader size='small' color={COLORS.blue} height='auto' />
              }
              type='submit'
              variant='primary'
              disabled={!isValid || isLoading}
            />
            <SubTitleCommon color={COLORS.black50} mt={SPACES.xxs}>
              Don't have an account?
              <SubTitleCommon
                cursor='pointer'
                ml={SPACES.xxxxs}
                onClick={onNavigate}
                color={COLORS.blue}
              >
                Sign up
              </SubTitleCommon>
            </SubTitleCommon>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};
