import React, { ReactNode } from 'react';

import * as Styled from './page-layout.styled';

export interface IPageLayout {
  isBackground?: boolean;
  children: ReactNode;
}

export const PageLayout = ({ children, isBackground = true }: IPageLayout) => (
  <Styled.Container>
    {isBackground && <Styled.BackgroundDiv isBackground={isBackground} />}
    <Styled.Div isBackground={isBackground}>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Div>
  </Styled.Container>
);
