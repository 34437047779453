export const COLORS = {
  white: '#fff',
  white50: '#F4F4F4',
  white100: '#F3F3F3',
  white200: '#F5F6FE',
  white250: '#d0d5dd',
  semiWhite: '#d0d5dd',

  black: '#000',
  black20: 'rgba(0, 0, 0, 0.80)',
  black25: 'rgba(0, 0, 0, 0.60)',
  black30: 'rgba(0, 0, 0, 0.50)',
  black50: '#0A0A0A',
  black100: 'rgba(0, 0, 0, 0.25)',
  black125: 'rgba(0, 0, 0, 0.20)',
  black115: 'rgba(0, 0, 0, 0.15)',
  black150: 'rgba(0, 0, 0, 0.10)',
  black200: 'rgba(0, 0, 0, 0.03)',
  black250: 'rgba(0, 0, 0, 0.05)',

  gray: '#6B6775',
  gray100: '#98a2b3',

  purple: '#A458BE',
  purple20: '#CD41FF',
  purple40: '#A458BE',
  purple100: '#F7DFFF',
  blue: '#0086AB',
  blue20: '#00789A',
  blue100: '#D4F2FF',
  orange: '#D68C35',
  orange50: '#C29D3B',
  orange100: '#FFEAD2',

  pink: '#FFC0C0',
  pinkActive: '#ff9292',

  mainRed: 'rgba(255, 87, 87, 1)',
  red50: '#ec091f',
  red100: '#A330x30',
  red125: '#A23030',
  red150: '#FCE6E6',
  green: 'rgba(54, 208, 187, 1)',
  green15: 'rgba(54, 208, 187, 0.15)',
  green30: 'rgba(54, 208, 187, 0.3)',
  greenActiveButton: '#59e2cf',
  green07: 'rgba(54, 208, 187, 0.7)',
  greenActive: 'rgba(36, 193, 171, 1)',
  green50: '#33a95d',
  green100: 'rgba(230, 252, 235, 0.90)'
};
