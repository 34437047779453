import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../theme';
import { IDivCommon, IIcon } from '../types';
import { Fonts } from './text.styled';

export const Center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivCommon = styled.div<IDivCommon>`
  width: ${({ width }) => width ?? '100%'};
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  height: ${({ height }) => height ?? 'fit-content'};
  margin: ${({ margin }) => margin ?? '0 auto'};
  padding: ${({ padding }) => padding ?? '0'};
  gap: ${({ gap }) => gap ?? '0'};

  display: flex;
  flex-direction: ${({ fd }) => fd ?? 'column'};
  justify-content: ${({ jc }) => jc ?? 'stretch'};
  align-items: ${({ ai }) => ai ?? 'stretch'};

  position: relative;

  &.scroll {
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0.125rem;
      height: 1.25rem;
    }
  }

  ${({ background }) => background && `background: ${background};`}
  ${({ anyStyled }) => anyStyled}
`;

export const IconCommon = styled.div<IIcon>`
  height: ${({ height }) => height ?? '1.5rem'};

  aspect-ratio: 1/1;
  background: ${({ background }) => background ?? COLORS.black50};

  -webkit-mask-image: url(${({ icon }) => icon});
  -webkit-mask-size: 100% 100%;
  mask-image: url(${({ icon }) => icon});
  cursor: ${({ cursor }) => cursor ?? 'text'};
`;

export const DrawerContainer = styled.div`
  width: 26.563rem;
  height: 100%;
  background-color: ${COLORS.white};
  overflow-y: scroll;

  padding: ${SPACES.xxxxxxls} ${SPACES.xxxxl};

  &::-webkit-scrollbar {
    width: 0.25rem;
    background-color: ${COLORS.black50};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.green};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 22rem;
  }
`;

const HoverAndActive = css`
  background: ${COLORS.green};
  color: ${COLORS.white};

  & > .icon {
    background: ${COLORS.white};
  }
`;

export const CommonSettingNavigationItem = styled.div<{ isActive?: boolean }>`
  width: fit-content;
  align-items: center;

  display: flex;

  border-radius: 1.5rem;

  gap: ${SPACES.s};
  padding: ${SPACES.xxs} ${SPACES.l};

  ${Fonts};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black25};

  cursor: pointer;

  & > .icon {
    background: ${COLORS.black25};
  }

  &.button {
    &:hover {
      ${HoverAndActive}
    }

    ${({ isActive }) => isActive && HoverAndActive}
  }
`;

export const CommonTableContainer = styled.div`
  width: 100%;
  max-height: 31.25rem;

  padding: ${SPACES.l} ${SPACES.xxxxl};

  background: ${COLORS.white};

  border-radius: 0.75rem;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.125rem;
    height: 1.25rem;
  }
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.green50};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.green};
  }

  scrollbar-width: thin;
  scrollbar-color: ${COLORS.green50} ${COLORS.black250};
`;

export const CommonPaginationContainer = styled.div`
  width: 50%;
  padding: 0 ${SPACES.l};
  margin: 0 auto;
`;
