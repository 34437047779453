import { APP_KEYS } from '../../module/common/constants';
import { api } from '../../module/common/services';
import { IMessage } from '../../module/common/types';
import { IUserResponse } from '../../types';

export const userService = {
  getByIdOrToken: async (): Promise<IUserResponse> => {
    const { data } = await api.get<IUserResponse>('/user');

    return data;
  },

  logout: async (): Promise<IMessage> => {
    const refreshToken = localStorage.getItem(APP_KEYS.STORAGE_KEYS.REFRESH_TOKEN) || '';
    const { data } = await api.post<IMessage>(
      '/user/logout',
      {},
      { headers: { update: refreshToken } }
    );

    return data;
  }
};
