import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { APP_KEYS } from '../../../common/constants';
import './circle.css';

interface ICircle {
  page: number;
  keyword?: string;
  duration: number;
}
export const Circle = ({ page, keyword, duration }: ICircle) => {
  const [remainingTime, setRemainingTime] = useState<number>(30 * 1000);
  const [isRunning, setIsRunning] = useState<boolean>(true);

  const client = useQueryClient();

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    const startTimer = () => {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 0) {
            
            client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_LIST, page, keyword]).then();
            setIsRunning(false);
            clearInterval(intervalId as NodeJS.Timeout);
            return duration * 1000;
          }
          if (prevTime === 30000) {
            client.invalidateQueries([APP_KEYS.QUERY_KEYS.ALL_LIST, page, keyword]).then();
          }
          return Math.max(0, prevTime - 1000);
        });
      }, 1000);
    };

    if (isRunning) {
      startTimer();
    } else {
      clearInterval(intervalId as NodeJS.Timeout);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning, duration, page, keyword]);

  useEffect(() => {
    if (remainingTime === 0) {
      setIsRunning(true);
      setRemainingTime(duration * 1000);
    }
  }, [remainingTime, duration]);

  const minutes = Math.floor(remainingTime / 30000);
  const seconds = Math.floor((remainingTime % 30000) / 1000);
  const timeString = seconds?.toString().length === 2 ? `${seconds}s` : `0${seconds}s`;

  const progress = (duration * 1000 - remainingTime) / (duration * 1000);
  const hue = 120 - progress * 120;
  const color = `hsl(${hue}, 100%, 50%)`;

  return (
    <div className='countdown-container'>
      <svg className='circle' style={{ transform: 'rotate(-90deg)' }}>
        <circle cx='50%' cy='50%' r='25' fill='none' className='circle-bg' />
        <circle
          cx='50%'
          cy='50%'
          r='25'
          fill='none'
          className='circle-fill'
          style={{ strokeDasharray: '157', strokeDashoffset: `${progress * 157}`, stroke: color }}
        />
      </svg>
      <div className='time'>{timeString}</div>
    </div>
  );
};
