import { AxiosError } from 'axios';

import { IAuthError } from '../../../types';
import { toastContainer } from '../component/toast';
import { IMessage } from '../types';

export const onError = (_err: AxiosError<IAuthError>) => {
  toastContainer.error({ title: _err.message });
};

export const onSuccess = ({ message }: IMessage) => {
  toastContainer.success({ title: message });
};
