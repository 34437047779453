import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { APP_KEYS } from '../constants';

export const PublicPage = ({ children }: { children?: any }) => {
  const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

  if (token) {
    return <Navigate to={APP_KEYS.ROUTER_KEYS.HOME} replace />;
  }

  return children || <Outlet />;
};
