import { Form, Formik, getIn } from 'formik';
import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { useMutationWallet } from '../../../../hooks';
import { COLORS, FONTS } from '../../../../theme';
import { SIZES } from '../../../../theme/fonts.const';
import { IEditMerch, IWalletAddresses } from '../../../../types';
import { Button, Input, ModalConfirm } from '../../../common/component';
import { DropDownlist } from '../../../common/component/drop-down-list/drop-downlist';
import { PopupLayout } from '../../../common/component/popup-layout/popup-layout';
import { Portal } from '../../../common/component/portal';
import * as StyledTable from '../../../common/component/table/table.styled';
import { DivCommon, IconCommon, TitleCommon } from '../../../common/styles';
import * as Styled from '../../merch.styled';

interface IProps {
  data: IWalletAddresses[];
}

export const MerchTable = ({ data }: IProps) => {
  const [editMerch, setEditMerch] = useState<IEditMerch | null>(null);
  const [isOpenUpdateWallet, setIsOpenUpdateWallet] = useState<null | number>(null);
  const [isOpenDeleteWallet, setIsOpenDeleteWallet] = useState<null | number>(null);

  const { mutate: mutateUpdateWalletAddressItem } = useMutationWallet.put();
  const { mutate: mutateDeleteWalletAddressItem } = useMutationWallet.remove();

  const onEditMerch = (value: IEditMerch | null) => {
    setEditMerch(value);
  };

  const onSetIsOpenUpdate = (_data: null | number) => {
    setIsOpenUpdateWallet(_data);
  };

  const onSubmitEdit = (_data: any) => {
    mutateUpdateWalletAddressItem({ id: _data.id, name: _data.name, token: _data.token.name });
    setEditMerch(null);
    onSetIsOpenUpdate(null);
  };

  const onSetIsOpenDelete = (_data: null | number) => {
    setIsOpenDeleteWallet(_data);
  };

  const deleteWallet = () => {
    if (isOpenDeleteWallet) {
      mutateDeleteWalletAddressItem({ id: isOpenDeleteWallet });
      onSetIsOpenDelete(null);
    }
  };

  return (
    <DivCommon width='90%' ai='center' margin='1.5rem auto 0 auto'>
      <Formik
        initialValues={{
          name: editMerch?.name ?? '',
          address: editMerch?.address ?? '',
          token: { name: editMerch?.token ?? '' },
          balance: editMerch?.balance ?? '',
          id: editMerch?.id,
          merchants: data ?? []
        }}
        enableReinitialize
        onSubmit={onSubmitEdit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <StyledTable.Table>
              <StyledTable.Head>
                <StyledTable.Row>
                  <StyledTable.HeadRow>№</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Name</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Address</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Balance</StyledTable.HeadRow>
                  <StyledTable.HeadRow>Token</StyledTable.HeadRow>

                  <StyledTable.HeadRow className='button'>
                    <IconCommon icon={Icons.edit} height={FONTS.SIZES.xxxxl} />
                  </StyledTable.HeadRow>

                  <StyledTable.HeadRow className='button'>
                    <IconCommon icon={Icons.basket} height={FONTS.SIZES.xxxxl} />
                  </StyledTable.HeadRow>
                </StyledTable.Row>
              </StyledTable.Head>

              <StyledTable.Body>
                {getIn(values, 'merchants') && getIn(values, 'merchants').length
                  ? getIn(values, 'merchants')?.map((value: any, index: number) => (
                      <>
                        <Styled.BodyRow
                          className='body'
                          key={`${value.id}_${index}`}
                          isEdit={editMerch?.id === value.id}
                          isBlur={editMerch?.id ? editMerch.id !== value.id : false}
                        >
                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.id.toString()}
                            >
                              {index + 1}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>
                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.name}
                            >
                              {value.name}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.address}
                            >
                              {value.address}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value?.balance?.toString() ?? ''}
                            >
                              {value?.balance}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={value.token}
                            >
                              <DropDownlist
                                list={value.tokens}
                                addOptionalOnChange={(_, value) => {
                                  setFieldValue(`merchants[${index}].balance`, value.balance);
                                  setFieldValue('token', value.name);
                                }}
                                name={`merchants[${index}].token`}
                                listStyle={{ top: '2.4rem' }}
                                wrapperStyle={{
                                  border: `0.0625rem solid ${COLORS.black100}`,
                                  height: '2.25rem',
                                  margin: `${SIZES.xxxxxs} 0`
                                }}
                                noFormikValue={{
                                  value: value.token,
                                  onSetValue: (name, _value) =>
                                    onSubmitEdit({
                                      id: value.id,
                                      name: value.name,
                                      token: { name: _value.name }
                                    })
                                }}
                              />
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data
                            className={editMerch?.id === value.id ? 'doubleButton' : 'button'}
                            onClick={onEditMerch.bind(this, value)}
                          >
                            <StyledTable.EditButton type='button'>
                              <IconCommon
                                icon={editMerch?.id === value.id ? Icons.editActive : Icons.edit}
                                cursor='pointer'
                              />
                            </StyledTable.EditButton>
                          </StyledTable.Data>

                          {editMerch?.id !== value.id && (
                            <StyledTable.Data
                              className='button'
                              onClick={onSetIsOpenDelete.bind(this, value.id)}
                            >
                              <StyledTable.EditButton type='button'>
                                <IconCommon icon={Icons.basket} cursor='pointer' />
                              </StyledTable.EditButton>
                            </StyledTable.Data>
                          )}
                        </Styled.BodyRow>

                        {editMerch?.id === value.id && (
                          <Styled.BodyRow className='body edit_item' key={`${value.id}${index}`}>
                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.id.toString()}
                              >
                                {index + 1}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>
                            <StyledTable.Data className='title'>
                              <Input name='name' height='2.25rem' />
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.address}
                              >
                                {value.address}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.balance?.toString() ?? ''}
                              >
                                {value.balance}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='title'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={value.token}
                              >
                                <DropDownlist
                                  list={value.tokens}
                                  addOptionalOnChange={(_, value) => {
                                    setFieldValue(`merchants[${index}].balance`, value.balance);
                                    setFieldValue('token', value);
                                  }}
                                  name={`merchants[${index}].token`}
                                  listStyle={{ top: '2.4rem' }}
                                  wrapperStyle={{
                                    border: `0.0625rem solid ${COLORS.black100}`,
                                    height: '2.25rem',
                                    margin: '0.125rem 0'
                                  }}
                                />
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>

                            <StyledTable.Data className='confirm'>
                              <StyledTable.EditButton
                                type='button'
                                onClick={onSetIsOpenUpdate.bind(this, 1)}
                              >
                                <IconCommon icon={Icons.confirm} cursor='pointer' />
                              </StyledTable.EditButton>
                            </StyledTable.Data>
                            <StyledTable.Data
                              className='cancel'
                              onClick={onEditMerch.bind(this, null)}
                            >
                              <StyledTable.EditButton>
                                <IconCommon icon={Icons.cancel} cursor='pointer' />
                              </StyledTable.EditButton>
                            </StyledTable.Data>
                          </Styled.BodyRow>
                        )}
                      </>
                    ))
                  : null}
              </StyledTable.Body>
            </StyledTable.Table>
            {isOpenUpdateWallet ? (
              <ModalConfirm
                onClose={onSetIsOpenUpdate.bind(this, null)}
                title='Are You Shure?'
                titleButtonConfirm='Confirm'
                titleButtonCancel='Cancel'
                onClickConfirm={onSubmitEdit.bind(this, values)}
                onClickCancel={onSetIsOpenUpdate.bind(this, null)}
              />
            ) : null}
          </Form>
        )}
      </Formik>

      {data.length ? null : <Styled.NoData>No Data</Styled.NoData>}

      {isOpenDeleteWallet ? (
        <ModalConfirm
          onClose={onSetIsOpenDelete.bind(this, null)}
          title='Are You Shure?'
          titleButtonConfirm='Confirm'
          titleButtonCancel='Cancel'
          onClickConfirm={deleteWallet}
          onClickCancel={onSetIsOpenDelete.bind(this, null)}
        />
      ) : null}
    </DivCommon>
  );
};
