import React from 'react';

import { Icons } from '../../../../../assets';
import { ICloseButtonProps } from '../../../types';
import * as Styled from './close-button.styled';

export const CloseButton = ({ width, height, ...props }: ICloseButtonProps) => (
  <Styled.Button {...props}>
    <Styled.CloseIcon src={Icons.closeIcon} alt='close' width={width} height={height} />
  </Styled.Button>
);
