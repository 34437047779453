import * as Yup from 'yup';

import { commonValidation, validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaLogin = Yup.object().shape({
  email: validateSchema.email,
  password: validateSchema.password
});

export const validationSchemaSingUp = Yup.object().shape({
  ...commonValidation,
  password: validateSchema.password,
  confirm_password: validateSchema.password.oneOf(
    [Yup.ref('password')],
    'The password confirmation does not match'
  ),
  privacy_policy: Yup.boolean().required('is required')
});

export const validationSchemaForgot = Yup.object().shape({
  email: validateSchema.email
});

export const validationSchemaCreatePassword = Yup.object().shape({
  password: validateSchema.password,
  confirm_password: validateSchema.password.oneOf(
    [Yup.ref('password')],
    'The password confirmation does not match'
  )
});
