import styled from 'styled-components';

import s from '../popup-layout/popup-layout.module.css';

export const Wrapper = styled.div`
  background: red;
  & > div > div {
    width: 38.75rem;
    height: 19.625rem;
  }
`;
