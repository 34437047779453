import styled, { css } from 'styled-components';

import { COLORS } from '../../../../theme';
import { Row } from '../../../common/component/table/table.styled';

export const BodyRow = styled(Row)<{ isEdit?: boolean; isBlur?: boolean }>`
  & > td.button {
    cursor: pointer;
  }

  & > td > strong.token {
    text-align: center;
  }

  &.edit_item {
    & > .confirm {
      background: ${COLORS.greenActiveButton};
      text-align: center;
      align-content: end;
      cursor: pointer;

      &:hover {
        background: ${COLORS.green};
      }
    }

    & > .cancel {
      background: ${COLORS.pink};
      text-align: center;
      align-content: end;
      cursor: pointer;

      &:hover {
        background: ${COLORS.pinkActive};
      }
    }

    & input {
      margin: 0;
      border: none;
      outline: none;
      caret-color: black;
    }
  }

  ${({ isEdit }) =>
    isEdit &&
    css`
      & > td > strong {
        font-weight: 600;
        color: black;
      }
    `}
  ${({ isBlur }) =>
    isBlur &&
    css`
      opacity: 0.5;
    `}
`;

export const NoData = styled.div`
  min-height: 200px;
`;
