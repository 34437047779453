import styled from 'styled-components';

export const CreateWalletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;

  &.input > ul.list {
    top: 20px !important;
  }
`;
