import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { Iuuid } from '../../module/common/types';
import { pageService } from '../../services';
import { IPageResponse, IPagesResponse } from '../../types';

const all = (): UseQueryResult<IPagesResponse> =>
  useQuery([APP_KEYS.QUERY_KEYS.WALLET_ALL_PAGES], async () => await pageService.all());

const get = (data: Iuuid): UseQueryResult<IPageResponse> =>
  useQuery(
    [APP_KEYS.QUERY_KEYS.WALLET_PAGE_INFO, data.id],
    async () => await pageService.get(data),
    {
      enabled: !!data.id
    }
  );

export const useQueryPages = {
  all,
  get
};
