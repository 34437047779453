import { api } from '../../module/common/services';
import { ExpectedFromQuery, IMessage } from '../../module/common/types';
import {
  IAllFilters,
  IAllList,
  ICurrentAddressData,
  IWallet,
  IWalletDelete,
  IWalletUpdate
} from '../../types';

export const walletService = {
  all: async ({ page, keyword = '' }: IAllFilters): Promise<ExpectedFromQuery<IAllList>> => {
    const { data: _data } = await api.get<IAllList>('/wallet/all', { params: { page, keyword } });

    return _data;
  },

  get: async (data: string): Promise<ExpectedFromQuery<ICurrentAddressData>> => {
    const { data: _data } = await api.get<ICurrentAddressData>('/wallet/', {
      params: { address: data }
    });

    return _data;
  },

  post: async (data: IWallet): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.post<IMessage>('/wallet/', data);

    return _data;
  },

  put: async (data: IWalletUpdate): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.put<IMessage>('/wallet/', data);

    return _data;
  },

  remove: async (data: IWalletDelete): Promise<ExpectedFromQuery<IMessage>> => {
    const { data: _data } = await api.delete<IMessage>(`/wallet/?id=${data.id}`);

    return _data;
  }
};
