import styled, { css } from 'styled-components';

import { COLORS, FONTS } from '../../../theme';
import { IFonts } from '../types';
import { Margin } from './margin.styled';

export const Fonts = css<IFonts>`
  font-family: ${({ ff }) => ff ?? FONTS.FAMILIES.source};
  font-weight: ${({ fw }) => (fw ? `${fw} !important` : FONTS.WEIGHTS.normal)};
  font-size: ${({ fs }) => (fs ? `${fs} !important` : FONTS.SIZES.m)};
  line-height: ${({ lh }) => (lh ? `${lh} !important` : FONTS.SIZES.xxl)};
  color: ${({ color }) => color ?? COLORS.black};
  cursor: ${({ cursor }) => cursor ?? 'text'};

  text-align: ${({ ta }) => ta ?? 'left'};

  ${({ width }) =>
    width &&
    css<{ width?: string }>`
      width: ${({ width }) => width};
    `}

  ${Margin};
`;

export const TitleCommon = styled.p<IFonts>`
  ${Fonts};
  font-family: ${FONTS.FAMILIES.figtree};
  font-weight: ${({ fw }) => (fw ? `${fw} !important` : FONTS.WEIGHTS.medium)};
  font-size: ${({ fs }) => (fs ? `${fs} !important` : FONTS.SIZES.xxxxxxl)};

  //font-size: ${FONTS.SIZES.xxxxxxl};
  //font-size: 46px;
  //line-height: 1.5;
  line-height: 52.5px;
  ${Margin};
  display: flex;
  justify-content: center;
`;

export const SubTitleCommon = styled.span`
  display: flex;
  justify-content: center;
  ${Fonts};
`;
