export const SHADOWS = {
  xxs: '0 1px 2px rgba(16, 24, 40, 0.05)',
  lg: '0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)',
  xs: '0 1px 2px rgba(16, 24, 40, 0.05)',
  m: '0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06)',
  ml: '0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)',
  l: '0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(250, 117, 130, 0.11)',
  xl: '0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03)',
  xxl: '0 4px 18px 0 rgba(0, 0, 0, 0.15)'
};
