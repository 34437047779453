import { getIn, useField, useFormikContext } from 'formik';
import React, { ChangeEvent, memo, useEffect, useRef, useState } from 'react';

import { Icons } from '../../../../../assets';
import { SPACES } from '../../../../../theme';
import { FilePath } from '../../../../../utils';
import { RegexConst, passwordError } from '../../../constants';
import { IconCommon } from '../../../styles';
import { IInputProps } from '../../../types';
import * as Styled from './input.styled';

export const Input = memo(
  ({
    noFormikValue,
    name,
    label,
    gapFromLabel,
    textarea,
    checkbox,
    height,
    radio,
    startIcon,
    endIcon,
    innerPads,
    required,
    className,
    type = 'text',
    labelClassName,
    inputType = 1,
    isOptional = false,
    autoFocus = false,
    addOptionalOnChange,
    ...props
  }: IInputProps) => {
    const { setFieldValue, value, field, touched, error } = (() => {
      if (noFormikValue) {
        return {
          touched: false,
          error: '',
          field: {
            onChange: () => {}
          },
          value: noFormikValue.value,
          setFieldValue: noFormikValue.onSetValue
        };
      }
      const [field, { touched, error }] = useField(name);
      return {
        touched,
        error,
        field,
        value: getIn(useFormikContext().values, name),
        setFieldValue: useFormikContext().setFieldValue
      };
    })();
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, e.target.value);
      addOptionalOnChange && addOptionalOnChange(name, e.target.value);
    };
    const isCommonError = touched && error;

    const pads =
      (startIcon ? `${SPACES.xs} ${SPACES.xxxxxxl_}` : innerPads) ||
      (type === 'password' ? `${SPACES.xs} ${SPACES.xxxxxxl} ${SPACES.xs} ${SPACES.m}` : undefined);

    const [types, setTypes] = useState(type);
    const [isPassword, setIsPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string[]>([]);

    useEffect(() => {
      if (type === 'password' && isPassword) {
        setTypes('text');
      } else {
        setTypes(type);
      }
    }, [type, isPassword]);

    useEffect(() => {
      if (name.includes('password')) {
        const addStr = (str: string) => {
          if (!successMessage.includes(str)) {
            setSuccessMessage((prev) => [...prev, str]);
          }
        };
        const deleteStr = (str: string) => {
          const index = successMessage.indexOf(str);

          if (index > -1) {
            setSuccessMessage((prev: any) => {
              prev.splice(index, 1);

              return [prev];
            });
          }
        };
        const is = (reservation: any, _error: string) => {
          if (reservation) {
            addStr(_error);
          } else {
            deleteStr(_error);
          }
        };

        is(RegexConst.LOWERCASE.test(value), passwordError[0]);
        is(RegexConst.CAPITAL.test(value), passwordError[1]);
        is(RegexConst.SPECIAL.test(value), passwordError[2]);
        is(value?.length >= 8, passwordError[3]);
      }
    }, [name, value]);

    const onClickPassword = () => {
      setIsPassword(!isPassword);
    };

    const isPasswordError = !!isCommonError && passwordError.includes(error ?? '');

    const ref = useRef<HTMLDivElement | null>(null);

    const [top, setTop] = useState('50%');

    useEffect(() => {
      if (type === 'password' || startIcon || endIcon) {
        const calculateTopHeight = () => {
          const childrenRef = ref.current?.children;
          // @ts-ignore
          const childrenRefHTML = Array.from(childrenRef ?? []) as HTMLCollectionOf<HTMLElement>;
          const childrenRefHTMLArray = Array.from(childrenRefHTML).splice(0, 2);

          return (
            childrenRefHTMLArray.reduce((acc, child, i) => {
              if (i === 1) {
                return acc + child.offsetHeight / 2.5;
              }

              return acc + child.offsetHeight;
            }, 0) + 'px'
          );
        };

        setTop(calculateTopHeight());
      }
    }, []);
    return (
      <Styled.Wrapper className={className} isLabel={!!label} {...props} ref={ref} top={top}>
        {label && inputType === 1 && (
          <Styled.Label
            isError={!!isCommonError}
            required={required}
            htmlFor={name}
            className={labelClassName}
          >
            {label}
            {isOptional && <Styled.LabelOptional>· Optional</Styled.LabelOptional>}
          </Styled.Label>
        )}

        {inputType === 1 ? (
          <Styled.Input
            height={height}
            id={name}
            name={name}
            autoComplete='off'
            autoFocus={autoFocus}
            isError={!!isCommonError}
            gapFromLabel={gapFromLabel}
            innerPads={pads}
            type={types}
            {...props}
            {...field}
            value={noFormikValue ? noFormikValue.value : value}
            onChange={onChange}
            // onChange={(e) => setFieldValue(name, e.target.value as string)}
          />
        ) : (
          <Styled.Input2 id={name} name={name} type={types} required {...field} {...props} />
        )}

        {label && inputType === 2 && (
          <Styled.Label2 htmlFor={name} className={labelClassName}>
            {label}
          </Styled.Label2>
        )}

        {startIcon && <IconCommon {...startIcon} className='startIcon' />}
        {endIcon && <IconCommon {...endIcon} className='endIcon' />}

        {type === 'password' &&
          (isPassword ? (
            <Styled.Visibility
              height={top}
              isError={isPasswordError}
              isCommonError={!!isCommonError}
              onClick={onClickPassword}
            />
          ) : (
            <Styled.VisibilityOff
              height={top}
              isError={isPasswordError}
              isCommonError={!!isCommonError}
              onClick={onClickPassword}
            />
          ))}

        {isCommonError &&
        !passwordError.includes(error ?? '') &&
        error !== 'is required' &&
        error !== 'invalid date' ? (
          <Styled.Error>{error}</Styled.Error>
        ) : null}

        {isPasswordError ? (
          <Styled.ErrorPasswordContainer>
            {passwordError.map((text, index) => {
              const isError = text === error;
              const isSuccess = successMessage.includes(text);
              return (
                <Styled.ErrorPassword isError={isError} isSuccess={isSuccess} key={index}>
                  <Styled.Icon
                    isError={isError}
                    isSuccess={isSuccess}
                    style={{
                      WebkitMaskImage: `url(${FilePath(Icons.success)})`,
                      WebkitMaskSize: '100% 100%',
                      maskImage: `url(${FilePath(Icons.success)})`
                    }}
                  />

                  {text}
                </Styled.ErrorPassword>
              );
            })}
          </Styled.ErrorPasswordContainer>
        ) : null}
      </Styled.Wrapper>
    );
  }
);
